import { createTheme } from "@mui/material/styles";

export const whiteTheme = createTheme({
  palette: {
    mode: "light",
  },

  normal: {
    primary: "#F69C00",
    primary_hover: "#FFC933",
    primary_active: "#CC9600",
    primary_light_hover: "rgba(234, 170, 0, 0.4)",
    primary_light_active: "rgba(234, 170, 0, 0.3)",
    success: "#2BB55A",
    success_hover: "#2CCC6C",
    success_active: "#1C894F",
    success_light_hover: "#CFF5D0",
    success_light_active: "#A2EBA5",
    danger: "#E84262",
    danger_hover: "#FF5B77",
    danger_active: "#CC3A52",
    danger_light_hover: "#FFD8CC",
    danger_light_active: "#FFAE99",
    bg0: "#FFFFFF",
    bg1: "#F0F1F5",
    bg2: "#FFFFFF",
    bg3: "#F0F1F5",
    bg4: "#FFFFFF",
    text0: "#030308",
    text1: "rgba(3, 3, 8, 0.8)",
    text2: "rgba(3, 3, 8, 0.6)",
    text3: "rgba(3, 3, 8, 0.35)",
    highLight: "#FFFFFF",
    highDark: "#030308",
    border: "rgba(28, 28, 35, 0.08)",
    disabled_bg: "#E6E7EA",
    disabled_text: "rgba(28, 28, 35, 0.35)",
    disabled_border: "#E6E7EA",
    disabled_fill: "rgba(46, 46, 56, 0.04)",
    grey: "#F0F1F5",
    grey_hover: "#E4E5EC",
    grey_active: "#C2C5DA",
    grey_light_hover: "#E4E5EC",
    grey_light_active: "#F0F1F5",
    overlay_bg: "rgba(0, 0, 0, 0.7)",
  },
  isLight: true,
  breakpoints: {
    values: {
      xs: 0,
      ssm: 400,
      sm: 768,
      md: 992,
      lg: 1200,
      xl: 1600,
    },
  },
});

export const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
  normal: {
    primary: "#F69C00",
    primary_hover: "#FFC933",
    primary_active: "#CC9600",
    primary_light_hover: "rgba(234, 170, 0, 0.3)",
    primary_light_active: "rgba(234, 170, 0, 0.4)",
    success: "#2BB55A",
    success_hover: "#2CCC6C",
    success_active: "#1C894F",
    success_light_hover: "rgba(54, 197, 110, 0.3)",
    success_light_active: "rgba(54, 197, 110, 0.4)",
    danger: "#E84262",
    danger_hover: "#FF5B77",
    danger_active: "#CC3A52",
    danger_light_hover: "rgba(255, 79, 51, 0.3)",
    danger_light_active: "rgba(255, 79, 51, 0.4)",
    bg0: "#171A1F",
    bg1: "#1F2329",
    bg2: "#1F2329",
    bg3: "#35373D",
    bg4: "#313236",
    text0: "#F9F9F9",
    text1: "rgba(249, 249, 249, 0.8)",
    text2: "rgba(249, 249, 249, 0.6)",
    text3: "rgba(249, 249, 249, 0.35)",
    highLight: "#FFFFFF",
    highDark: "#030308",
    border: "rgba(255, 255, 255, 0.08)",
    disabled_bg: "#2E2E38",
    disabled_text: "rgba(249, 249, 249, 0.35)",
    disabled_border: "#2E2E38",
    disabled_fill: "rgba(230, 231, 234, 0.04)",
    grey: "#1F2329",
    grey_hover: "#35373D",
    grey_active: "#484A52",
    grey_light_hover: "rgba(30, 32, 39, 0.3)",
    grey_light_active: "rgba(47, 50, 59, 0.4)",
    overlay_bg: "rgba(0, 0, 0, 0.7)",
  },
  isLight: false,
  breakpoints: {
    values: {
      xs: 0,
      ssm: 450,
      sm: 768,
      md: 992,
      lg: 1200,
      xl: 1600,
    },
  },
});
